<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<!-- KeywordList -->
		<KeywordsList></KeywordsList>

		<!-- Source List -->
		<SourceList></SourceList>

		<!-- Show finish source selection  -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.source_selection.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import SourceList from "@/components/research/modules/gatherSources/source/List.vue";
import KeywordsList from "@/components/research/modules/gatherSources/source/KeywordsList.vue";

export default {
	name: "SourceSelectionPage",

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		StageIntroDialog,
		SourceList,
		KeywordsList,
		StageFinishDialog,
	},

	created() {
		this.$eventBus.$on("finish-source-selection", (payload) => {
			this.dialog = true;
		});
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("finish-source-selection");
	},
};
</script>
